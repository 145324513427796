import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Request from '../../Utils/Api'
import { getRoleName } from '../../Utils/authService';
import { UserRoles } from '../../Enums/UserRoles';
import { toast } from 'react-toastify';

const RequestInvestigationModal = ({ data, city }) => {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        fullName: `${data.pFirst} ${data.pFather} ${data.pGrand}`,
        famNo: `${data.famNo}`,
        city: `${city}`,
        town: `${data.rcName}`,
        phoneNumbers: '',
        emails: '',
        socialMediaAccounts: '',
        notes: '',
        photoUrl: '',
    });

    const handleShow = () => {
        if(getRoleName() === UserRoles.PrimeUser.GetName()) {
            setShow(true);
        } else {
            toast.error('يجب أن يكون لديك حساب مميز، ليس لديك صلاحية إرسال طلب تحري');
        }
    }
    const handleClose = () => setShow(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        Request("POST", `/api/RequestInvestigation/RequestInvestigation`, formData)
            .then(() => {
                toast.success('تم إرسال الطلب بنجاح');
                handleClose();
            })
            .catch((error) => {
                toast.error('فشل في إرسال الطلب');
            });
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                تــحري
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>طلب تحري</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFullName">
                            <Form.Label>الاسم الكامل</Form.Label>
                            <Form.Control
                                type="text"
                                name="fullName"
                                value={`${data.pFirst} ${data.pFather} ${data.pGrand}`}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group controlId="formFamNo">
                            <Form.Label>رقم العائلة</Form.Label>
                            <Form.Control
                                type="text"
                                name="famNo"
                                value={`${data.famNo}`}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group controlId="formCity">
                            <Form.Label>المدينة</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={`${city}`}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>المنطقة</Form.Label>
                            <Form.Control
                                type="text"
                                name="town"
                                value={`${data.rcName}`}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group controlId="formPhoneNumbers">
                            <Form.Label>أرقام الهواتف (غير مطلوب)</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="phoneNumbers"
                                value={formData.phoneNumbers}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmails">
                            <Form.Label>البريد الإلكتروني (غير مطلوب)</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="emails"
                                value={formData.emails}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formSocialMediaAccounts">
                            <Form.Label>حسابات وسائل التواصل الاجتماعي (غير مطلوب)</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="socialMediaAccounts"
                                value={formData.socialMediaAccounts}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formPhotoUrl">
                            <Form.Label>رابط الصورة (مطلوبة اذا كنت لا تمتلك اي من البيانات السابقة)</Form.Label>
                            <Form.Control
                                type="url"
                                name="photoUrl"
                                value={formData.photoUrl}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formNotes">
                            <Form.Label>ملاحظات (غير مطلوب)</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="notes"
                                value={formData.notes}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                    <br />
                    <p style={{ color: '#fd6868', fontSize: '15px' }}>
                        يستغرق البحث من ثلاثة ايام الى اسبوعين (حسب الحالة) بعد قبول الطلب و يكون سعر البحث من 20 الى 250 دولار (حسب البيانات) . <br />
                        اذا تم اهمال اي من البيانات المطلوبة سيتم رفض الطلب.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        إغلاق
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        إرسال
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RequestInvestigationModal;