import React, { useState } from "react";
import "../../Styles/facebook.css";
import Request from '../../Utils/Api';
import { toast } from "react-toastify";

const FacebookSearch = () => {
    const [data, setData] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSearch = () => {
        if (phoneNumber.length < 8 || phoneNumber.length > 15) {
            toast.error("رقم الهاتف يجب أن يكون بين 8 و 15 رقمًا");
            return;
        }

        setLoading(true);
        Request("GET", `/api/Facebook/Search?PhoneNumber=${phoneNumber}`)
            .then(response => {
                setData(response);
                setLoading(false);
            })
            .catch(err => {
                if (err.response.status === 400 && err.response.data.message !== null) {
                    toast.error(err.response.data.message);
                }

                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            })
    };

    const renderResults = () => {
        if (data.length === 0 && !loading) {
            return <p className="no-data">لا توجد بيانات</p>;
        }

        return data.map((item, index) => {
            const [id, phone, firstName, lastName, gender, username, profile, fullName, jobTitle, university, email] = item.split(',');

            return (
                <div key={index} className="result-card">
                    <p><strong>رقم التعريف:</strong> {id}</p>
                    <p><strong>رقم الهاتف:</strong> {phone}</p>
                    <p><strong>الاسم الأول:</strong> {firstName}</p>
                    <p><strong>الكنية:</strong> {lastName}</p>
                    <p><strong>الجنس:</strong> {gender}</p>
                    <p><strong>اسم المستخدم:</strong> {username}</p>
                    <p><strong>الملف الشخصي:</strong> {profile}</p>
                    <p><strong>الاسم الكامل:</strong> {fullName}</p>
                    <p><strong>الوظيفة:</strong> {jobTitle}</p>
                    <p><strong>الجامعة:</strong> {university}</p>
                    <p><strong>البريد الإلكتروني:</strong> {email}</p>
                </div>
            );
        });
    };

    return (
        <div className="container-fluid dashboard-content">
            <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-6">
                    <div className="search-form">
                        <label htmlFor="phoneNumber">أدخل رقم الهاتف</label>
                        <input
                            type="text"
                            id="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="مثال: 7517584242"
                            className="form-control"
                        />
                    </div>
                    <div className="mt-3">
                        <button onClick={handleSearch} disabled={loading} className="btn search-form-button w-100">
                            {loading ? "جاري البحــــث ..." : "بحث"}
                        </button>
                    </div>
                </div>
            </div>
            <div className="results-container mt-4">
                {renderResults()}
            </div>
        </div>
    );
};

export default FacebookSearch;
