import React, { useState, useEffect } from 'react';
import { Table, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import '../../Styles/ListView.css';

const VoterbaseListView = ({ byCity, data, fetchData }) => {
  const [currentPage, setCurrentPage] = useState(data.currentPage || 1);
  const totalPages = data.totalPages;
  const [currentEntries, setCurrentEntries] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentEntries(data.data);
    setCurrentPage(data.currentPage || 1);
  }, [data]);

  const handlePageChange = async (pageNumber) => {
    await fetchData(pageNumber);
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const maxDisplayedPages = 5;
    let startPage;
    let endPage;

    if (totalPages <= maxDisplayedPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxDisplayedPages / 2)) {
        startPage = 1;
        endPage = maxDisplayedPages;
      } else if (currentPage + Math.floor(maxDisplayedPages / 2) > totalPages) {
        startPage = totalPages - maxDisplayedPages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxDisplayedPages / 2);
        endPage = currentPage + Math.floor(maxDisplayedPages / 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (totalPages > maxDisplayedPages) {
      paginationItems.unshift(
        <Pagination.Item
          key="prev"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Prev
        </Pagination.Item>
      );

      paginationItems.push(
        <Pagination.Item
          key="next"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  // function truncateString(str) {
  //   if (str.length > 10) {
  //     return str.substring(0, 10) + "...";
  //   }
  //   return str;
  // }

  return (
    <>
      <div className="list-view">
        {currentEntries.length === 0 ? (
          <p className="no-data-message">No data to display. Please select a page number to load the data.</p>
        ) : (
          <div className="table-container">
            <Table responsive bordered hover variant="dark" className="responsive-table">
              <thead>
                <tr>
                  <th>رقم الناخب</th>
                  <th>الاسم</th>
                  <th>تاريخ الميلاد</th>
                  <th>المدرسة</th>
                  <th>المنطقة</th>
                  <th>رقم العائلة</th>
                  {byCity === false ? <th>المحافظة</th> : ""}
                </tr>
              </thead>
              <tbody>
                {currentEntries.map((item) => (
                  <tr key={`${Date.now()}-${Math.floor(Math.random() * 10000)}`}>
                    <td data-label="رقم الناخب">{`${item.perId}`}</td>
                    <td data-label="الاسم">{`${item.fullName}`}</td>
                    <td data-label="تاريخ الميلاد">{item.perDob}</td>
                    <td data-label="المدرسة">{item.pcName}</td>
                    <td data-label="المنطقة">{item.pcAddress}</td>
                    <td data-label="رقم العائلة">{item.perFamno}</td>
                    {byCity === false ? <td data-label="المحافظة">{item.govNameAr}</td> : ""}

                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
      <Pagination className="d-flex justify-content-center mt-3">
        {renderPaginationItems()}
      </Pagination>
    </>);
};

export default VoterbaseListView;