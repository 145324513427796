import React, { useState, useEffect } from "react";
import ListView from "./ListView";
import "../Styles/BgdSearch.css";
import Request from '../Utils/Api'
import { toast } from "react-toastify";
import { Modal, Button } from 'react-bootstrap';

const AdvancedSearchModal = ({ handleShow, show, handleClose, familyMemberName, setFamilyMemberName }) => {
  return (
    <>
      <Modal show={show}>
        <Modal.Body>
          <Modal.Title style={{ fontWeight: "bold" }}>بحث متقدم</Modal.Title>
          <br />
          <div className="row">
            <h6>اسم احد افراد العائلة</h6>
            <div className="col-md-12">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="لديه فرد من العائلة باسم (الاسم الاول فقط)"
                value={familyMemberName}
                onChange={(e) => setFamilyMemberName(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            حذف جميع الحقول
          </Button>
          <Button variant="primary" onClick={handleShow}>
            تم
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const BgdSearch = () => {
  const [Name, setName] = useState('');
  const [City, setCity] = useState('');
  const [DOB, setDOB] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const activeCity = "baghdad";
  const [show, setShow] = useState(false);
  const [familyMemberName, setFamilyMemberName] = useState('');

  const handleClose = () => {
    setFamilyMemberName('');
    setShow(false);
  };

  const handleShow = () => setShow(!show);

  useEffect(() => {
    const cachedCityOptions = localStorage.getItem('baghdad');
    if (cachedCityOptions) {
      setCityOptions(JSON.parse(cachedCityOptions));
    } else {
      fetchCityOptions();
    }
  }, []);

  useEffect(() => {
    if (cityOptions.length > 0) {
      localStorage.setItem('baghdad', JSON.stringify(cityOptions));
    }
  }, [cityOptions]);

  const fetchCityOptions = () => {
    Request("GET", `/api/General/GetCityDropdown?cityName=${activeCity}`)
      .then(data => {
        setCityOptions(data);
      })
      .catch(error => {
        toast.error("حدث خطأ ما اثناء جلب المناطق !");
      });
  };

  const Search = (page = 1) => {
    if (!Name || Name.length < 2) {
      toast.error("الاسم مطلوب");
      return;
    }

    setLoading(true);
    Request("POST", "/api/Baghdad/search?pageNumber=" + page + "&pageSize=100", {
      name: Name,
      city: City.length > 2 ? City : null,
      date: DOB,
      familyMemberName: familyMemberName
    }).then(data => {
      setData(data)
      setLoading(false);
    });
  };

  const handleCityChange = (e) => {
    const newCity = e.target.value;
    setCity(newCity);
  };

  const handleCityBlur = (e) => {
    if (e.target.value !== "" && e.target.value !== null && cityOptions.indexOf(e.target.value) === -1) {
      toast.error("اسم المنطقة غير صحيح");
      setCity('');
    }
  };

  return (
    <>
      <div className="container-fluid dashboard-content">
        <h2 className="mt-4">بغداد</h2>
        <div className="search-inputs">
          <div className="row">
            <div className="col-md-3 col-lg-2">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="الاسم"
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-lg-2">
              <div className="input-container">
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="اسم المنطقة مثل (كرادة)"
                  value={City}
                  onChange={handleCityChange}
                  onBlur={handleCityBlur}
                  list="city-options"
                />
                <datalist id="city-options" className="datalist-container">
                  {cityOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </datalist>
              </div>
            </div>
            <div className="col-md-3 col-lg-2">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="سنة التولد مثل (1935)"
                onChange={e => setDOB(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-lg-2">
              <Button variant="primary" onClick={handleShow}>
                بحث متقدم
              </Button>
              <AdvancedSearchModal
                show={show}
                handleClose={handleClose}
                familyMemberName={familyMemberName}
                setFamilyMemberName={setFamilyMemberName}
                handleShow={handleShow}
              />
            </div>
            <div className="col-md-12">
              <button className="btn btn-primary mt-3" onClick={() => Search()} disabled={loading}>بحث</button>
            </div>
          </div>
        </div>
        {
          loading ? "جاري البحــــث ..... " : data?.data?.length > 0 ? <ListView data={data} fetchData={(page) => Search(page)} city={activeCity} /> : ""
        }
      </div>
    </>
  );
};

export default BgdSearch;