import React, { useState, useEffect } from 'react';
import { Table, Button, Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import Request from '../../../Utils/Api';
import CreateUserModal from './CreateUserModal';
import '../../../Styles/User.css';

const UserListView = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchUsers = (pageNumber = 1) => {
    setLoading(true);
    Request("GET", `/api/GetUsers?SearchKey=&pageNumber=${pageNumber}&pageSize=10`)
      .then(response => {
        setData(response.data);
        setTotalPages(response.totalPages);
        setLoading(false);
      })
      .catch(() => {
        toast.error("Error fetching users");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCreateUser = (newUser) => {
    // Close the modal and refresh the user list after creation
    setModalOpen(false);
    fetchUsers(currentPage);
  };

  return (
    <div className='container-fluid dashboard-content'>
      <div className="list-view">
        <div className="d-flex justify-content-between align-items-center">
          <h2>المستخدمين</h2>
          <Button variant="primary" onClick={() => setModalOpen(true)}>انشاء مستخدم</Button>
        </div>
        {loading && <p>جاري التحميل...</p>}
        {data.length === 0 ? (
          <p className="no-data-message"> لا توجد بيانات.</p>
        ) : (
          <div className="table-container">
            <Table responsive bordered hover variant="dark" className="responsive-table">
              <thead>
                <tr>
                  <th>الاسم</th>
                  <th>اسم المستخدم</th>
                  <th>معلومات التواصل</th>
                  <th>تاريخ الانتهاء</th>
                  {/* <th>#</th> */}
                </tr>
              </thead>
              <tbody>
                {data.map((user) => (
                  <tr key={user.id}>
                    <td data-label="الاسم">{user.name}</td>
                    <td data-label="اسم المستخدم">{user.username}</td>
                    <td data-label="معلومات التواصل">{user.contactInfo}</td>
                    <td data-label="تاريخ الانتهاء">{new Date(user.expireDate).toISOString().split('T')[0]}</td>
                    {/* <td data-label="#"></td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
      <Pagination className="d-flex justify-content-center mt-3">
        {[...Array(totalPages)].map((_, i) => (
          <Pagination.Item
            key={i + 1}
            active={i + 1 === currentPage}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      <CreateUserModal
        show={modalOpen}
        handleClose={() => setModalOpen(false)}
        onUserCreated={handleCreateUser}
      />
    </div>
  );
};

export default UserListView;