import axios from 'axios';
import { toast } from 'react-toastify';

const BASE_URL = 'https://iraq-leaks.xyz'
//const BASE_URL = 'http://localhost:5003'

const api = axios.create({
    baseURL: BASE_URL,
});

const getToken = () => localStorage.getItem('token');

const request = async (method, url, data = {}) => {
    try {
        const token = getToken();

        if (token) {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete api.defaults.headers.common['Authorization'];
        }

        const response = await api({
            method,
            url,
            data,
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.reload();
            } else if (error.response.status === 429) {
                toast.error('لقد وصلت إلى الحد الأقصى للطلب. يرجى المحاولة بعد دقيقة .', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                });
            } else if (error.response.status === 400 && url !== "/api/Login") {
                toast.error('حدث خطأ ما ! يرجى أعادة المحاولة', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                });
            } else if (error.response.status === 500) {
                toast.error('هناك خطأ من الخادم ..', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                });
            }
        }
        throw error;
    }
};

export default request;
