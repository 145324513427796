import React, { useEffect, useState } from "react";
import "../Styles/ShowFamily.css"; // Import the CSS file
import Request from '../Utils/Api';
import { useLocation } from "react-router-dom";

const ShowFamily = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { fam_no } = location.state || "";
  const { city } = location.state || "";

  useEffect(() => {
    const url = `${city}`.length > 2 ? "/api/AllCities/all/family?pageNumber=1&pageSize=1" : "/api/Baghdad/family?pageNumber=1&pageSize=1";
    setLoading(true);
    Request("POST", url, {
      city: city,
      family_num: fam_no
    }).then(data => {
      setData(data);
      setLoading(false);
    });
  }, [fam_no]);

  const ShowFamilyContent = () => {
    return (
      <div className="showFamily-container">
        <h1>Family Data</h1>
        <h3>رقم العائلة : {data[0]?.famNo}</h3>
        <h3>المنطقة : {data[0]?.rcName ?? data[0]?.ssBrNm?.trim()}</h3>
        <h3>المحلة : {data[0]?.fArea?.trim() ?? data[0]?.ssLgNo?.trim()}</h3>
        <h3>الزقاق : {data[0]?.fStreet?.trim() ?? data[0]?.ssBrNo?.trim()}</h3>
        <h3>الدار : {data[0]?.fHouse?.trim() ?? data[0]?.ssPgNo?.trim()}</h3>
        <div className="showFamily-table-container">
          <table className="showFamily-table">
            <thead className="showFamily-thead">
              <tr>
                <th className="showFamily-th">الاسم الاول</th>
                <th className="showFamily-th">الاب</th>
                <th className="showFamily-th">الجد</th>
                <th className="showFamily-th">الميلاد</th>
              </tr>
            </thead>
            <tbody className="showFamily-tbody">
              {data.map((member, index) => (
                <tr key={index} className="showFamily-tr">
                  <td className="showFamily-td" data-label="الاسم الاول">{member.pFirst}</td>
                  <td className="showFamily-td" data-label="الاب">{member.pFather}</td>
                  <td className="showFamily-td" data-label="الجد">{member.pGrand}</td>
                  <td className="showFamily-td" data-label="الميلاد">{member.pBirth}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="container-fluid">
        {
          loading ? "جاري البحــــث ..... " : data?.data?.length > 0 ? "No Data" : ShowFamilyContent()
        }
      </div>
    </>
  );
};

export default ShowFamily;
