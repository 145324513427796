import React, { useState, useEffect } from 'react';
import { Table, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import '../Styles/ListView.css';
import RequestInvestigationModal from './RequestInvestigation/RequestInvestigationModal'

const ListView = ({ data, fetchData, city }) => {
  const [currentPage, setCurrentPage] = useState(data.currentPage || 1);
  const totalPages = data.totalPages;
  const [currentEntries, setCurrentEntries] = useState([]);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => setModalOpen(false);

  useEffect(() => {
    setCurrentEntries(data.data);
    setCurrentPage(data.currentPage || 1);
  }, [data]);

  const handlePageChange = async (pageNumber) => {
    await fetchData(pageNumber);
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const maxDisplayedPages = 5;
    let startPage;
    let endPage;

    if (totalPages <= maxDisplayedPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxDisplayedPages / 2)) {
        startPage = 1;
        endPage = maxDisplayedPages;
      } else if (currentPage + Math.floor(maxDisplayedPages / 2) > totalPages) {
        startPage = totalPages - maxDisplayedPages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxDisplayedPages / 2);
        endPage = currentPage + Math.floor(maxDisplayedPages / 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (totalPages > maxDisplayedPages) {
      paginationItems.unshift(
        <Pagination.Item
          key="prev"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Prev
        </Pagination.Item>
      );

      paginationItems.push(
        <Pagination.Item
          key="next"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  return (
    <>
      <div className="list-view">
        {currentEntries.length === 0 ? (
          <p className="no-data-message">No data to display. Please select a page number to load the data.</p>
        ) : (
          <div className="table-container">
            <Table responsive bordered hover variant="dark" className="responsive-table">
              <thead>
                <tr>
                  <th>الاسم</th>
                  <th>تاريخ الميلاد</th>
                  <th>المنطقة</th>
                  <th>العنوان</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries.map((item) => (
                  <tr key={`${Date.now()}-${Math.floor(Math.random() * 10000)}`}>
                    <td data-label="الاسم">{`${item.pFirst} ${item.pFather} ${item.pGrand}`}</td>
                    <td data-label="تاريخ الميلاد">{item.pBirth?.substring(0, 4)}</td>
                    <td data-label="المنطقة">{item.rcName ?? item.ssBrNm}</td>
                    <td data-label="العنوان">{`${item.fArea ?? item.ssLgNo}م, ${item.fStreet ?? item.ssBrNo}ز, ${item.fHouse ?? item.ssPgNo}د`}</td>
                    <td data-label="#">
                      <button className="btn btn-primary" onClick={() => navigate('/show-family', { state: { fam_no: item.famNo, city: data.city } })}>عرض المزيد</button>
                      <RequestInvestigationModal open={modalOpen} onClose={handleCloseModal}  data={item} city={city}/>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
      <Pagination className="d-flex justify-content-center mt-3">
        {renderPaginationItems()}
      </Pagination>
    </>);
};

export default ListView;