import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import BgdSearch from './Pages/BgdSearch';
import LoginPage from './Pages/LoginPage';
import './Styles/index.css';
import NotFoundPage from './Pages/NotFoundPage';
import ShowFamily from './Pages/ShowFamily';
import OtherCitiesSearch from './Pages/OtherCitiesSearch';
import ProtectedRoute from './Pages/ProtectedRoute';
import Info from './Pages/Info';
import VoterbasePage from './Pages/Voterbase/VoterbasePage';
import Navbar from "./Pages/Navbar";
import { getRoleName, isLoggedIn } from './Utils/authService';
import FacebookSearch from './Pages/Facebook/FacebookSearch';
import PopularMobilizationForcesSearch from './Pages/PopularMobilizationForces/PopularMobilizationForcesSearch';
import RegistrationPage from './Pages/RegistrationPage';
import { UserRoles } from './Enums/UserRoles';
import UserListView from './Pages/Admin/UserManagement/UserListView';

const Root = () => {
  const _isLoggedIn = isLoggedIn();
  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Router>
        {_isLoggedIn ? <Navbar /> : null}
        <Routes>
          {/* Public Routes */}
          <Route
            exact path="/login"
            element={_isLoggedIn ? <Navigate to="/" /> : <LoginPage />}
          />
          <Route
            path="/info"
            element={<Info />}
          />

          {/* Protected Routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute isLoggedIn={_isLoggedIn}>
                <BgdSearch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/show-family"
            element={
              <ProtectedRoute isLoggedIn={_isLoggedIn}>
                <ShowFamily />
              </ProtectedRoute>
            }
          />
          <Route
            path="/other-cities"
            element={
              <ProtectedRoute isLoggedIn={_isLoggedIn}>
                <OtherCitiesSearch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/voterbase"
            element={
              <ProtectedRoute isLoggedIn={_isLoggedIn}>
                <VoterbasePage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/facebook"
            element={
              <ProtectedRoute isLoggedIn={_isLoggedIn}>
                <FacebookSearch />
              </ProtectedRoute>
            }
          />

          <Route
            path="/popular-mobilization-forces"
            element={
              <ProtectedRoute isLoggedIn={_isLoggedIn}>
                <PopularMobilizationForcesSearch />
              </ProtectedRoute>
            }
          />

          <Route
            path="/register"
            element={
              <ProtectedRoute isLoggedIn={!_isLoggedIn}>
                <RegistrationPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/user"
            element={
              <ProtectedRoute isLoggedIn={_isLoggedIn && getRoleName() === UserRoles.Admin.GetName()}>
                <UserListView />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
