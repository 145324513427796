export const UserRoles = {
    Admin: {
        id: 0,
        roleName: 'Admin',
        label: 'مدير نظام',
        GetName() {
            return this.roleName;
        }
    },
    PrimeUser: {
        id: 1,
        roleName: 'PrimeUser',
        label: 'مميز',
        GetName() {
            return this.roleName;
        }
    },
    FreeUser: {
        id: 2,
        roleName: 'FreeUser',
        label: 'مجاني',
        GetName() {
            return this.roleName;
        }
    }
};
// Function to get role name by ID
export const getRoleNameById = (id) => {
    for (const [key, value] of Object.entries(UserRoles)) {
        if (value.id === id) {
            return value.roleName;
        }
    }
    return null;
};

export const getRoleLabelById = (id) => {
    for (const [key, value] of Object.entries(UserRoles)) {
        if (value.id === id) {
            return value.label;
        }
    }
    return null;
};

export const getRoleIdByName = (name) => {
    for (const [key, value] of Object.entries(UserRoles)) {
        if (value.roleName === name) {
            return value.id;
        }
    }
    return null;
};
