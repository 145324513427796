import React, { useState } from 'react';
import '../Styles/info.css';

const Info = () => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus('');

    try {
      const webhookURL = 'https://discord.com/api/webhooks/1268650291214028993/SikKcgLthXCFVSHPLoQN66lSaI1bHekxZPqi0gnBqelpe0ZblJg2pOFxzPe-ZGPylLng';
      const payload = {
        content: `**New Message from ${name}**\n\n${message}`,
      };

      const response = await fetch(webhookURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setStatus('تم ارسال الرسالة بنجاح !');
        setName('');
        setMessage('');
      } else {
        setStatus('حدث خطأ ما يرجى المحاولة مرة اخرى.');
      }
    } catch (error) {
      setStatus('يرجى التحقق من اتصال بالانترنت !!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid dashboard-content info-page">
      <center>
        <h1 style={{ marginBottom: '100px', marginTop: '100px' }} >IraqLeaks - مصدر معلوماتك المسربة في العراق</h1>
        <p>هذه الموقع هو أول موقع لتسريب البيانات في العراق، حيث يمكنك بسهولة البحث عن نفسك أو عن أي شخص مولود في العراق.</p>
        <p>لبدء الاستخدام، يجب أن تأخذ كلمة المرور من القناة في التلجرام. <a href="https://t.me/iraq_leaks">اضغط هنا للتحويل إلى القناة</a>.</p>
        <p>ثانيًا، يمكنك الذهاب إلى صفحة تسجيل الدخول والبدء بالاستخدام. <a href="login">اضغط هنا لتحويلك إلى صفحة تسجيل الدخول</a>.</p>
        <hr style={{ width: '80%', marginBottom: '100px', marginTop: '100px' }} />
        <h1>للتواصل</h1>
        <h4 onClick={() => window.open('https://t.me/iraq_leaks', '_blank')}>
          تليكرام : @iraq_leaks
        </h4>
        <br />
        <br />
        <br />
        <form onSubmit={handleSubmit} style={{ marginTop: '20px', maxWidth: '600px', margin: 'auto' }}>
          <div className="form-group">
            <label htmlFor="name">يوزرك  للتواصل معك:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="ايميلك ,يوزرك تليكرام, انستاكرام للتواصل معك"
              style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">رسالتك:</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              rows="4"
              placeholder="راسالتك"
              style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'جاري الارسال...' : 'ارسال الرسالة'}
          </button>
          {status && <p style={{ marginTop: '10px' }}>{status}</p>}
        </form>
      </center>
    </div>
  );
};

export default Info;
