import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItemButton, ListItemText, CssBaseline } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import '../Styles/Navbar.css';
import { UserRoles } from '../Enums/UserRoles';
import { getRoleName } from '../Utils/authService';

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: '#303030' }}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <div className="desktop-menu">
            <Link to="/" className="navbar-link">بغداد</Link>
            <Link to="/other-cities" className="navbar-link">محافظات</Link>
            <Link to="/voterbase" className="navbar-link">ناخبين</Link>
            <Link to="/facebook" className="navbar-link">فيسبوك</Link>
            <Link to="/popular-mobilization-forces" className="navbar-link prominent">حشد الشعبي</Link>
            {getRoleName() === UserRoles.Admin.GetName() && <Link to="/admin/user" className="navbar-link">المستخدمين</Link>}
            <Link to="/info" className="navbar-link">تواصل معنا</Link>
            <Link onClick={() => {
              localStorage.removeItem("token");
              window.location.reload();
            }} className="navbar-link">تسجيل خروج</Link>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        PaperProps={{
          sx: {
            backgroundColor: '#303030',
            color: 'white',
            width: 240,
          },
        }}
      >
        <List>
          <ListItemButton component={Link} to="/" onClick={toggleDrawer}>
            <ListItemText primary="بغداد" sx={{ textAlign: 'right' }} />
          </ListItemButton>
          <ListItemButton component={Link} to="/other-cities" onClick={toggleDrawer}>
            <ListItemText primary="محافظات" sx={{ textAlign: 'right' }} />
          </ListItemButton>
          <ListItemButton component={Link} to="/voterbase" onClick={toggleDrawer}>
            <ListItemText primary="ناخبين" sx={{ textAlign: 'right' }} />
          </ListItemButton>
          <ListItemButton component={Link} to="/facebook" onClick={toggleDrawer}>
            <ListItemText primary="فيسبوك" sx={{ textAlign: 'right' }} />
          </ListItemButton>
          <ListItemButton component={Link} to="/popular-mobilization-forces" onClick={toggleDrawer}>
            <ListItemText primary="حشد الشعبي" sx={{ textAlign: 'right' }} />
          </ListItemButton>
          {getRoleName() === UserRoles.Admin.GetName() && <ListItemButton component={Link} to="/admin/user" onClick={toggleDrawer}><ListItemText primary="المستخدمين" sx={{ textAlign: 'right' }} /></ListItemButton>}
          <ListItemButton component={Link} to="/info" onClick={toggleDrawer}>
            <ListItemText primary="من نحن & تواصل معنا" sx={{ textAlign: 'right' }} />
          </ListItemButton>
          <ListItemButton onClick={() => {
            localStorage.removeItem("token");
            window.location.reload();
          }}>
            <ListItemText primary="تسجيل خروج" sx={{ textAlign: 'right' }} />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
};

export default Navbar;
