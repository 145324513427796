import '../../Styles/ListView.css';

const PMFS_ListView = ({ data }) => {
  if (!data || data.length === 0) {
    return <p className="no-data-message">No data available</p>;
  }

  return (
    <div className="list-view">
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th style={{ backgroundColor: '#1f2327', color: 'white' }}>الاسم</th>
              <th style={{ backgroundColor: '#1f2327', color: 'white' }}>الام</th>
              <th style={{ backgroundColor: '#1f2327', color: 'white' }}>القسم</th>
              <th style={{ backgroundColor: '#1f2327', color: 'white' }}>الحالة</th>
              <th style={{ backgroundColor: '#1f2327', color: 'white' }}>ميلاد</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id} style={{ backgroundColor: '#fff', color: '#000' }}>
                <td style={{ backgroundColor: '#1f2327', color: 'white' }} data-label="الاسم">{item.fullName}</td>
                <td style={{ backgroundColor: '#1f2327', color: 'white' }} data-label="الام">{item.motherName}</td>
                <td style={{ backgroundColor: '#1f2327', color: 'white' }} data-label="القسم">{item.workDepartment}</td>
                <td style={{ backgroundColor: '#1f2327', color: 'white' }} data-label=" الحالة">{item.workStatus}</td>
                <td style={{ backgroundColor: '#1f2327', color: 'white' }} data-label="ميلاج">{item.dateOfBirth.replace("-01-01", '')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PMFS_ListView;
