import React, { useState } from 'react';
import '../Styles/RegisterPage.css';
import Request from '../Utils/Api'; // Ensure this is your API call utility
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    name: '',
    contactInfo: '',
    password: ''
  });
  const [formError, setFormError] = useState(false);
  const [showNotification, setShowNotification] = useState(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var usernamePattern = /^[a-zA-Z0-9_]{3,30}$/;
    if (!formData.username.match(usernamePattern)) {
      toast.error('اسم المستخدم يجب أن يحتوي على أحرف وأرقام فقط ويجب أن يكون بين 3 و 30 حرفًا.');
      return;
    }

    var namePattern = /^[a-zA-Z\s]{2,}$/;
    if (!formData.name.match(namePattern)) {
      toast.error('الاسم يجب أن يحتوي على حروف فقط ويجب أن يكون على الأقل حرفين.');
      return;
    }

    if (formData.password.length < 8) {
      toast.error('كلمة المرور يجب أن تحتوي على 8 أحرف على الأقل.');
      return;
    }


    

    Request("POST", "/api/register", formData)
      .then((response) => {
        setFormError(false);
        toast.success('تم التسجيل بنجاح!, سيتم تحويلك إلى صفحة تسجيل الدخول.');
        setTimeout(() => {
          navigate('/login');
        }, 1000);
      })
      .catch(err => {
        setFormError(true);
        if (err.response.status === 400 && err.response.data.message !== null) {
            toast.error(err.response.data.message);
        }
    })
};

  return (
    <div>
      {showNotification && (
        <div className="notification">
          <p>مرحباً بك! للتسجيل، يرجى ملء الحقول أدناه ثم الضغط على زر التسجيل.</p>
          <div className="notification-buttons">
            <button onClick={() => setShowNotification(false)}>أغلاق</button>
          </div>
        </div>
      )}
      <div className="matrix-bg">
        <div className="matrix-register-container">
          <form onSubmit={handleSubmit} className="matrix-form">
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="matrix-input"
              placeholder="اسم المستخدم"
              required
            />
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="matrix-input"
              placeholder="الاسم الكامل"
              required
            />
            <input
              type="text"
              name="contactInfo"
              value={formData.contactInfo}
              onChange={handleChange}
              className="matrix-input"
              placeholder="معلومات للتواصل معك"
              required
            />
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="matrix-input"
              placeholder="كلمة المرور"
              required
            />
            <button type="submit" className="matrix-submit-button">
              تسجيل
            </button>
          </form>
          {formError && <p className="error-msg">حدث خطأ أثناء التسجيل. حاول مرة أخرى.</p>}
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;