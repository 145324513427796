import React, { useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import Request from '../../Utils/Api'
import { toast } from "react-toastify";
import VoterbaseListView from "./VoterbaseListView";

const VoterbasePage = () => {
  const [Name, setName] = useState('');
  const [SchoolName, setSchoolName] = useState(null);
  const [data, setData] = useState([]);
  const [isSearchedByCity, setIsSearchedByCity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState('');
  const [FamilyNo, setFamilyNo] = useState('');
  const [cityOptions, setCityOptions] = useState([]);

  const Search = (page = 1) => {

    if (city !== "" && city !== null && cityOptions.indexOf(city) === -1) {
      toast.error("اسم المنطقة غير صحيح");
      setCity('');
      return;
    }

    setLoading(true);
    Request("POST", "/api/Voterbase/Search?pageNumber=" + page + "&pageSize=10", {
      name: Name,
      city: city,
      familyNo: FamilyNo,
      schoolName: SchoolName,
    }).then(_data => {
      setIsSearchedByCity(city.length > 1);
      setData(_data)
      setLoading(false);
    }).catch(err => {
      if (err.response.status === 400 && err.response.data.message !== null) {
        toast.error(err.response.data.message);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })
  }

  useEffect(() => {
    const cachedCityOptions = localStorage.getItem("VoterbaseCities");
    if (cachedCityOptions) {
      setCityOptions(JSON.parse(cachedCityOptions));
      setFamilyNo('')
    } else {
      Request("GET", `/api/Voterbase/GetCities`)
        .then(data => {
          localStorage.setItem("VoterbaseCities", JSON.stringify(data));
          setCityOptions(data)
          setFamilyNo('')
        })
        .catch(error => {
          toast.error("حدث خطأ ما اثناء جلب المناطق !");
        });
    }
  }, []);

  return (
    <>
      <div className="container-fluid dashboard-content">
        <h2 className="mt-4">بيانات الناخبين سنة 2017</h2>
        <div className="search-inputs">
          <div className="row">
            <div className="col-md-3 col-12">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="أسم الناخب"
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-12">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="اسم المدرسة"
                onChange={e => setSchoolName(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-12">
              <div className="input-container">
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="رقم العائلة"
                  value={FamilyNo}
                  onChange={(e) => setFamilyNo(e.target.value)}
                  list="city-options"
                />
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-1 col-6 d-flex align-items-center">
                  <button className="btn btn-primary w-100" onClick={() => Search()} disabled={loading}>
                    بحث
                  </button>
                </div>
                <div className="col-md-3 col-6 d-flex align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" >
                      {city || "اختر المحافظة"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {cityOptions.map((key) => (
                        <Dropdown.Item
                          key={key}
                          onClick={() => {
                            setCity(key);
                          }}
                        >
                          {key}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        {
          loading ? "جاري البحــــث ..... " : data?.data?.length > 0 ? <VoterbaseListView byCity={isSearchedByCity} data={data} fetchData={(page) => Search(page)} /> : ""
        }

      </div>
    </>
  );
};

export default VoterbasePage;