import React, { useEffect, useState } from "react";
import "../Styles/BgdSearch.css";
import ListView from "./ListView";
import { Dropdown } from 'react-bootstrap';
import Request from '../Utils/Api'
import { toast } from "react-toastify";

const OtherCitiesSearch = () => {
  const [Name, setName] = useState('');
  const [DOB, setDOB] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState('');
  const [SearchCity, setSearchCity] = useState('');
  const [cityOptions, setCityOptions] = useState([]);

  const cities = [
    { key: "anbar", label: "الأنبار" },
    { key: "babil", label: "بابل" },
    { key: "balad", label: "بلد" },
    { key: "basra", label: "البصرة" },
    { key: "dhiqar", label: "ذي قار" },
    { key: "diyala", label: "ديالى" },
    { key: "duhok", label: "دهوك" },
    { key: "erbil", label: "أربيل" },
    { key: "karbala", label: "كربلاء" },
    { key: "kirkuk", label: "كركوك" },
    { key: "maysan", label: "ميسان" },
    { key: "muthanna", label: "المثنى" },
    { key: "najaf", label: "النجف" },
    { key: "nineveh", label: "نينوى" },
    { key: "qadisiyyah", label: "القادسية" },
    { key: "salahaldin", label: "صلاح الدين" },
    { key: "sulaymaniyah", label: "السليمانية" },
    { key: "wasit", label: "الواسط" }
  ];

  const Search = (page = 1) => {
    if (!city || city.length < 3) {
      toast.error("يرجى اختيار محافظة للبحث !");
      return;
    }

    if (!Name || Name.length < 2) {
      toast.error("الاسم مطلوب !");
      return;
    }

    setLoading(true);
    Request("POST", "/api/AllCities/all?pageNumber=" + page + "&pageSize=100", {
      name: Name,
      city: city,
      searchCity: SearchCity,
      date: DOB,
    }).then(_data => {
      setData(_data)
      setLoading(false);
    })
  }

  useEffect(() => {
    if (city.length > 3) {
      const cachedCityOptions = localStorage.getItem(city);
      if (cachedCityOptions) {
        setCityOptions(JSON.parse(cachedCityOptions));
        setSearchCity('')
      } else {
        Request("GET", `/api/General/GetCityDropdown?cityName=${city}`)
          .then(data => {
            localStorage.setItem(city, JSON.stringify(data));
            setCityOptions(data)
            setSearchCity('')
          })
          .catch(error => {
            toast.error("حدث خطأ ما اثناء جلب المناطق !");
          });
      }
    }
  }, [city]);

  const handleCityBlur = (e) => {
    if (e.target.value !== "" && e.target.value !== null && cityOptions.indexOf(e.target.value) === -1) {
      toast.error("اسم المنطقة غير صحيح");
      setCity('');
    }
  };

  return (
    <>
      <div className="container-fluid dashboard-content">
        <h2 className="mt-4">محافظات</h2>
        <div className="search-inputs">
          <div className="row">
            <div className="col-md-3 col-12">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="الاسم"
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-12">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="سنة التولد مثل (1935)"
                onChange={e => setDOB(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-12">
              <div className="input-container">
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="المنطقة"
                  value={SearchCity}
                  onChange={(e) => setSearchCity(e.target.value)}
                  onBlur={handleCityBlur}
                  list="city-options"
                />
                <datalist id="city-options" className="datalist-container">
                  {cityOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </datalist>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-1 col-6 d-flex align-items-center">
                  <button className="btn btn-primary w-100" onClick={() => Search()} disabled={loading}>
                    بحث
                  </button>
                </div>
                <div className="col-md-3 col-6 d-flex align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" >
                      {cities.find(c => c.key === city)?.label || "اختر المحافظة"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {cities.map(({ key, label }) => (
                        <Dropdown.Item
                          key={key}
                          onClick={() => {
                            setCity(key);
                          }}
                        >
                          {label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        {
          loading ? "جاري البحــــث ..... " : data?.data?.length > 0 ? <ListView data={data} fetchData={(page) => Search(page)} city={city} /> : ""
        }

      </div>
    </>
  );
};

export default OtherCitiesSearch;