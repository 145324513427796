import { jwtDecode } from 'jwt-decode';
import { getRoleIdByName, getRoleLabelById } from '../Enums/UserRoles';

const TOKEN_KEY = 'token';

export const getToken = () => {
    return localStorage.getItem(TOKEN_KEY);
};

export const getClaims = () => {
    const token = getToken();
    if (!token) return null;

    try {
        const decodedToken = jwtDecode(token);
        return decodedToken;
    } catch (error) {
        console.error('Invalid token:', error);
        return null;
    }
};


export const getUserId = () => {
    const claims = getClaims();
    if (!claims) return null;

    return claims.Id;
};

export const isLoggedIn = () => {
    const claims = getClaims();
    if (!claims) return false;

    const currentTime = Date.now() / 1000;
    return claims.exp > currentTime;
};


export const getRoleName = () => {
    const claims = getClaims();
    if (!claims) return null;

    return claims.Role;
};

export const getRoleId = () => {
    const roleName = getRoleName();
    if (!roleName) return null;

    return getRoleIdByName(roleName);
};

export const getRoleLabel = () => {
    const roleId = getRoleId();
    if (roleId === null) return null;

    return getRoleLabelById(roleId);
};

