import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from "react-toastify";
import Request from '../../../Utils/Api';

const CreateUserModal = ({ show, handleClose, onUserCreated }) => {
    const [name, setname] = useState('');
    const [username, setusername] = useState('');
    const [contactInfo, setcontactInfo] = useState('');
    const [password, setpassword] = useState('');
    const [expireDate, setExpireDate] = useState(''); // New state for ExpireDate
    const [loading, setLoading] = useState(false);

    const handleCreate = () => {
        if (!name || !username || !contactInfo || !expireDate) {
            toast.error("جميع الحقول مطلوبة");
            return;
        }

        setLoading(true);
        const newUser = {
            name,
            username,
            password,
            contactInfo,
            expireDate
        };

        Request("POST", '/api/CreateUser', newUser)
            .then(() => {
                toast.success("تم إنشاء المستخدم بنجاح");
                handleClose();
                onUserCreated(newUser);
            })
            .catch(() => {
                toast.error("حدث خطأ ما أثناء إنشاء المستخدم");
                setLoading(false);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>انشاء مستخدم</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>الاسم</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="ادخل الاسم"
                            value={name}
                            onChange={(e) => setname(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>اسم المستخدم</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="ادخل اسم المستخدم"
                            value={username}
                            onChange={(e) => setusername(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>معلومات التواصل</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="ادخل معلومات التواصل"
                            value={contactInfo}
                            onChange={(e) => setcontactInfo(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>كلمة المرور</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="ادخل كلمة المرور"
                            value={password}
                            onChange={(e) => setpassword(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>تاريخ الانتهاء</Form.Label> {/* New field for ExpireDate */}
                        <Form.Control
                            type="date"
                            value={expireDate}
                            onChange={(e) => setExpireDate(e.target.value)}
                        />
                    </Form.Group>
                </Form>
                <Button variant="secondary" onClick={handleClose}>
                    اغلاق
                </Button> &nbsp;
                <Button variant="primary" onClick={handleCreate} disabled={loading}>
                    {loading ? "جاري الانشاء..." : "انشاء"}
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default CreateUserModal;
