import React, { useState } from "react";
import ListView from "./PMFS_ListView";
import Request from '../../Utils/Api';
import { toast } from "react-toastify";

const PopularMobilizationForcesSearch = () => {
  const [FullName, setFullName] = useState('');
  const [MotherName, setMotherName] = useState('');
  const [DateOfBirth, setDateOfBirth] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const isArabic = (text) => {
    const arabicPattern = /^[\u0600-\u06FF\s]+$/;
    return arabicPattern.test(text);
  };

  const Search = () => {
    if (!FullName || FullName.length < 2 || !isArabic(FullName)) {
      toast.error("يجب أن يكون الاسم مكونًا من حرفين على الأقل باللغة العربية");
      return;
    }

    setLoading(true);
    Request("GET", `/api/PopularMobilizationForces/Search?FullName=${FullName}&MotherName=${MotherName}&DateOfBirth=${DateOfBirth}`)
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error("حدث خطأ ما أثناء البحث");
        setLoading(false);
      });
  };

  return (
    <div className="container-fluid dashboard-content">
      <h2 className="mt-4">بحث في بيانات الحشد الشعبي</h2>
      <div className="search-inputs">
        <div className="row">
          <div className="col-md-3 col-lg-2">
            <input
              type="text"
              className="form-control mb-3"
              placeholder="الاسم الكامل"
              value={FullName}
              onChange={e => setFullName(e.target.value)}
            />
          </div>
          <div className="col-md-3 col-lg-2">
            <input
              type="text"
              className="form-control mb-3"
              placeholder="اسم الأم (اختياري)"
              value={MotherName}
              onChange={e => setMotherName(e.target.value)}
            />
          </div>
          <div className="col-md-3 col-lg-2">
            <input
              type="text"
              className="form-control mb-3"
              placeholder="تاريخ الميلاد (اختياري)"
              value={DateOfBirth}
              onChange={e => setDateOfBirth(e.target.value)}
            />
          </div>
          <div className="col-md-3 col-lg-2">
            <button className="btn btn-primary mt-3" onClick={Search} disabled={loading}>
              {loading ? "جاري البحــــث ..." : "بحث"}
            </button>
          </div>
        </div>
      </div>

      {data && data.length > 0 ? (
        <ListView data={data} />
      ) : (
        <p>لم يتم العثور على نتائج</p>
      )}
    </div>
  );
};

export default PopularMobilizationForcesSearch;
