import { useEffect } from "react"

const NotFoundPage = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = "/"
        }, 2000)
    }, [])

    return (
        <>
            <center>
                <h2>Page Not Found !!</h2>
            </center>
        </>)
}

export default NotFoundPage